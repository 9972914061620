/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import stylecss from "./Footer.module.css";
import ModelPopup from "./ModelPopup";
import RegisterForm from "./RegisterForm";

const Footer = (props) => {
  const [show, setShow] = useState(false);
  const handelClose = (child) => setShow(child);

  return (
    <>
      <footer>
        <div className="container_elm_2">
          <div className="row">
            <div className="col-md-3 col-12">
              <div className={stylecss.img_wrap}>
                <a href="/genius-hunt">
                  <img src="./images/footer_logo.svg" alt="footer_logo" />
                </a>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className={stylecss.content}>
                <h4>Next Event: Mumbai</h4>
                <h5>22 June 2022</h5>
                <h5>NES International School</h5>
                <h5 className="mt-3">
                  Mulund West, Mumbai, Maharashtra 400082
                </h5>
                <ul>
                  <li>
                    <a className="cursor-pointer" onClick={() => setShow(true)}>
                      Register Now
                    </a>{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className={`${stylecss.content} ${stylecss.content_2}`}>
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="/recent-highlights">Pune Event Highlights</a>
                  </li>
                  <li>
                    <a href="https://blogs.msvgo.com/">Blogs</a>
                  </li>
                  <li>
                    <a href="https://msvgo.com/">msvgo.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-12">
              <div
                className={`${stylecss.content} ${stylecss.social_media_wrap}`}
              >
                <h4>Our Social networks</h4>
                <div className={stylecss.social_media}>
                  <a href="https://www.facebook.com/MSVgo-100309464916276/">
                    <img src="./images/facebook_icon.png" alt="facebook_icon" />
                  </a>
                  <a href="https://www.instagram.com/msvgo/">
                    <img src="./images/insta_icon.png" alt="insta_icon" />
                  </a>
                </div>
                <div className="d-md-block d-flex align-items-center justify-content-center">
                  <div className={`${stylecss.appstore} me-3 d-md-none`}>
                    <a href="https://msvgo.com">
                      <img
                        src="./images/msvgo_logo_gehunt.png"
                        alt="googlestore_img"
                      />
                    </a>
                  </div>
                  <div className={stylecss.appstore}>
                    <a href="https://play.google.com/store/apps/details?id=com.hurix.msvgolive&hl=en_IN&gl=US">
                      <img
                        src="./images/googlestore_img.png"
                        alt="googlestore_img"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={stylecss.footer_copywrite}>
          <div className="container_elm_2">
            <div className="row align-items-center">
              <div className="col-md-6 col-12 mb-md-0 mb-4 text-md-start text-cneter">
                <p className="m-0">
                  © Tercera Venture Private Limited all rights reserved
                </p>
              </div>
              <div
                className={`col-md-6 col-12  text-md-end text-cneter ${stylecss.privacy}`}
              >
                <a href="https://msvgo.com/privacy-policy">Privacy Policy</a>
                &nbsp;&nbsp;
                <a href="https://msvgo.com/terms-of-use">Terms Of Use</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ModelPopup show={show} handelFromParent={handelClose}>
        <div className="registerPopup">
          <RegisterForm img="images/registerform_img_1.png" />
          <div className="row">
            <div className="col-lg-8 offset-lg-4 col-12">
              <div className="form-check p-0 mt-3">
                <label className="form-check-label">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name=""
                    id=""
                    value="checkedValue"
                    onChange={(e) =>
                      sessionStorage.setItem("hidePopup", e.target.checked)
                    }
                  />
                  Do not show this popup again
                </label>
              </div>
            </div>
          </div>
        </div>
      </ModelPopup>
    </>
  );
};

export default Footer;
