/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";
import axios from "axios";
// import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// import { Autoplay } from "swiper";
// import "swiper/swiper.min.css";
import { useParams, useNavigate } from "react-router-dom";
import geniusimg from "../../assets/images/msvgo_genius.svg";
import msvgo_log from "../../assets/images/msvgo_log.png";
import playbtn from "../../assets/images/Play.png";

const USER_API_URL = "https://api.msvgo.com";

function Leaderboard2(props) {
  const slug = 'runwalseagull';
  // const { slug } = useParams();
  const navigate = useNavigate();
  const [minHeight, setMinHeight] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [society, setSociety] = useState({});
  const [index, setIndex] = useState(0);
  const videos = [
    "./videos/1GameplayVideo.mp4",
    "./videos/2BrandMovie.mp4",
    "./videos/3ProductVideo.mp4",
    "./videos/4VideoMontage.mp4",
    "./videos/5GoVideo.mp4",
  ]
  // const videos = [
  //   "/videos/1GameplayVideo.mp4",
  //   "/videos/2BrandMovie.mp4",
  //   "/videos/3ProductVideo.mp4",
  //   "/videos/4VideoMontage.mp4",
  //   "/videos/5GoVideo.mp4",
  // ];

  let resizeWindow = () => {
    setMinHeight(window.innerHeight);
  };

  useEffect(() => {
    getSociety();
    getLeaderboard();
    resizeWindow();
    const timer = setInterval(() => getLeaderboard(), 30000);
    window.addEventListener("resize", resizeWindow);
    return () => {
      window.removeEventListener("resize", resizeWindow);
      clearInterval(timer);
    }
  }, []);

  const getSociety = () => {
    axios
      .post(`${USER_API_URL}/v1/genius_hunt/get_society`, { name: slug })
      .then((res) => {
        if (res.data.code === 200) {
          setSociety(res.data.data);
        } else {
          navigate("/");
        }
      });
  };

  const getLeaderboard = () => {
    axios
      .get(`${USER_API_URL}/v1/genius_hunt/leaderboard?name=${slug}`)
      .then((res) => {
        if (res.data.data) {
          setLeaderboard(res.data.data.slice(0, 10));
        }
      });
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Carousel variant="dark" controls={false} indicators={false} pause={false} activeIndex={index} onSelect={handleSelect}>
        {/* 5 Minutes */}
        <Carousel.Item interval={300000}>
          <div className="leaderboard_container leaderboard_bg">
            <div className="leaderboard">
              <div className="leaderboard_head mb-5">
                <div className="header_img text-center">
                  <img src={geniusimg} alt="geniusimg" />
                  <h3 className="text_yellow">{society?.name}</h3>
                  {/* <h3 className="text_light">LEADERBOARD</h3> */}
                </div>
              </div>
              <div className="listvideocontent">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 ">
                      <h5 className="text-light text-center">
                        REGISTER NOW at msvgo.com/genius-hunt/{society?.slug}
                      </h5>
                      <div className="video_wraper">
                        {/* <img src={playbtn} alt="playbtn" className="btn_play" /> */}
                        <video src={index === 0 ? videos[0] : ''} width="100%" autoPlay muted loop></video>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="leaderboard_list_wrap">
                        <div className="divtable">
                          <div className="head">
                            <div className="row">
                              <div className="col-2">RANK</div>
                              <div className="col-6">NAME</div>
                              <div className="col-2">GRADE</div>
                              <div className="col-2">SCORE</div>
                            </div>
                          </div>
                          <div className="tbody">
                            {leaderboard &&
                              leaderboard.map((leader, i) => {
                                return (
                                  <div className="row" key={"l_" + i}>
                                    <div className="col-2">{i + 1}</div>
                                    <div className="col-6">
                                      {leader?.user_name}
                                    </div>
                                    <div className="col-2">{leader?.grade}</div>
                                    <div className="col-2">
                                      {(
                                        99 +
                                        (14 * (leader?.max_score - 48)) / 34
                                      ).toFixed(0)}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="text-center bottom_content">
                          <h5>REGISTER NOW at msvgo.com/genius-hunt/{slug}</h5>
                          <img
                            src={msvgo_log}
                            alt="msvgo_log"
                            className="mdvgoLogo"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={44000} className="full-video-slide">
          <div className="video_wraper">
            {/* <img src={playbtn} alt="playbtn" className="btn_play" /> */}
            <video src={index === 1 ? videos[1] : ''} width="100%" autoPlay muted loop></video>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={44000} className="full-video-slide">
          <div className="video_wraper">
            {/* <img src={playbtn} alt="playbtn" className="btn_play" /> */}
            <video src={index === 2 ? videos[2] : ''} width="100%" autoPlay muted loop></video>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={25000} className="full-video-slide">
          <div className="video_wraper">
            <video src={index === 3 ? videos[3] : ''} width="100%" autoPlay muted loop></video>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={25000} className="full-video-slide">
          <div className="video_wraper">
            {/* <img src={playbtn} alt="playbtn" className="btn_play" /> */}
            <video src={index === 4 ? videos[4] : ''} width="100%" autoPlay muted loop></video>
          </div>
        </Carousel.Item>
      </Carousel>

    </>
  );
}

export default Leaderboard2;
