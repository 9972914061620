import React, { useState } from "react";
import styleCss from "./RecentHeighlights.module.css";
import ViewmoreMobileSlider from "./ViewmoreMobileSlider";

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import ModelPopup from "./ModelPopup";
const RecentHeighlights = () => {
  const [imgPopup, setimgPopup] = useState(false);
  const [imgPopupImag, setimgPopupImag] = useState("");
  const handleShowimgPopup = (e) => {
    setimgPopupImag(e.target.src);

    setimgPopup(true);
  };
  const handelCloseimgPopup = (child) => setimgPopup(child);
  return (
    <>
      <div className={styleCss.RecentHeighlights}>
        <div className={`container_elm ${styleCss.RecentHeighlightscontainer}`}>
          <div className={`row ${styleCss.particepent_details_row}`}>
            <div className="col-lg-6">
              <div className={styleCss.content}>
                <h3>Recent Highlights</h3>
                <p>
                  We found the first Math Genius in Pune. Catch all the action
                  from our smashing event to know what you can expect at the
                  next one!
                </p>
                <div
                  className={`d-flex align-items-center ${styleCss.particepent_gorup}`}
                >
                  <img
                    src="./images/group_img.png"
                    alt="group_img"
                    className={styleCss.group_img}
                  />
                  <p>
                    Over 100 Participants have already <br /> taken part in the
                    genius hunt
                  </p>
                </div>
                <div className={styleCss.particepent_details}>
                  <div className="row">
                    <div className="col-6 mb-4">
                      <div className={styleCss.particepent_details_elm}>
                        <h6>TOTAL PLAYERS</h6>
                        <h5>120</h5>
                      </div>
                    </div>
                    <div className="col-6  mb-4">
                      <div className={styleCss.particepent_details_elm}>
                        <h6>VENUE</h6>
                        <h5>Runwall Seagull Apts</h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={styleCss.particepent_details_elm}>
                        <h6>HIGHEST MATH IQ</h6>
                        <h5>137</h5>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={styleCss.particepent_details_elm}>
                        <h6>DATE</h6>
                        <h5>May, 2022</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <BrowserView>
                <div className={styleCss.gallery_wrap}>
                  <div className="row ms-5  align-items-center">
                    <div className="col-4 p-2">
                      <div className={styleCss.img_wrap}>
                        <img
                          src="./images/gal_1.png"
                          alt="gal_1"
                          draggable="false"
                          onClick={handleShowimgPopup}
                        />
                      </div>
                    </div>
                    <div className="col-8 p-2">
                      <img
                        src="./images/gal_3.png"
                        alt="gal_3"
                        draggable="false"
                        onClick={handleShowimgPopup}
                      />
                    </div>
                  </div>
                  <div className="row ms-4 me-1 align-items-center">
                    <div className="col-8 p-2">
                      <img
                        src="./images/gal_4.png"
                        alt="gal_3"
                        draggable="false"
                        onClick={handleShowimgPopup}
                      />
                    </div>
                    <div className="col-4 p-2">
                      <img
                        src="./images/gal_5.png"
                        alt="gal_1"
                        draggable="false"
                        onClick={handleShowimgPopup}
                      />
                    </div>
                  </div>
                  <div className="row me-5 align-items-center">
                    <div className="col-8 p-2">
                      <img
                        src="./images/gal_6.png"
                        alt="gal_3"
                        draggable="false"
                        onClick={handleShowimgPopup}
                      />
                    </div>
                    <div className="col-4 p-2">
                      <div className={styleCss.viewmore}>
                        <img
                          src="./images/gal_7.png"
                          alt="gal_1"
                          draggable="false"
                        />
                        <div className={styleCss.btn_viewmore}>
                          <a href="/recent-highlights"> View More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BrowserView>
              <MobileView>
                <ViewmoreMobileSlider />
                <div className={styleCss.viewmore_wraper}>
                  <a href="/genius-hunt"> View More</a>
                </div>
              </MobileView>
            </div>
          </div>
        </div>
        <div className="imgpopoup_wrap">
          <ModelPopup
            show={imgPopup}
            handelFromParent={handelCloseimgPopup}
            className="imgpopup"
            backdropClassName="imgpopupBackgroup"
          >
            <img src={imgPopupImag} alt="imgPopupImag" />
          </ModelPopup>
        </div>
      </div>
    </>
  );
};

export default RecentHeighlights;
