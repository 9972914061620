import React, { useState, useEffect } from "react";
import styleCss from "./RegisterForm.module.css";

const USER_API_URL = "https://stag-api.msvgo.com";
const RegisterForm = (props) => {
  const isPopup = props?.isPopup ?? false;
  const [name, setName] = useState("");
  const [internationalNumber, setInternationNumber] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("91");
  const [email, setEmail] = useState("");
  const [passCode, setPassCode] = useState("");
  const [otp, setOtp] = useState("");
  const [requestHeaders, setRequestHeaders] = useState([]);
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    getCountry();
  }, []);

  const getCountry = async () => {
    const url = `${USER_API_URL}/v1/content/getCountry`;
    const response = await fetch(url);
    const responseHeaders = Object.fromEntries(response.headers.entries());
    setRequestHeaders(responseHeaders);
  };

  const handleRegister = async () => {
    if (loading) return;
    setErrorMessage("");
    setSuccessMessage("");
    if (!name) {
      setErrorMessage("Please enter your name");
      return;
    }
    if (!mobileNumber) {
      setErrorMessage("Please enter your Parents Contact Number");
      return;
    }
    if (!mobileNumber.match(/^\d{10}$/)) {
      setErrorMessage("Please enter valid mobile number");
      return;
    }
    if (
      email &&
      !email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setErrorMessage("Please enter a valid email");
      return;
    }
    setLoading(true);
    let body = {
      name: name,
      username: "",
      email: email,
      mobile: mobileNumber,
      dob: "",
      school_id: 1,
      user_type: "student",
      state: requestHeaders["cloudfront-viewer-country-region-name"],
      city: requestHeaders["cloudfront-viewer-city"],
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await (
        await fetch(
          `${USER_API_URL}/v1/genius_hunt/register_user`,
          requestOptions
        )
      ).json();
      if (response.code === 200) {
        sessionStorage.setItem("hidePopup", true);
        setPassCode(response?.game_pass_code);
        if (props?.b2b) {
          setIsNew(false);
          setshowSuccessModal(true);
        } else {
          setIsNew(response?.is_new);
          if (response?.is_new) {
            setMobileNumber("");
            setEmail("");
            setName("");
            setSuccessMessage("Successfully registered");
            setTimeout(() => {
              setSuccessMessage("");
            }, 5000);
            // sendOtp();
          } else {
            setshowSuccessModal(true);
            setErrorMessage("Already registered");
            setTimeout(() => {
              setErrorMessage("");
            }, 5000);
          }
        }
        props?.isPopup && props?.registerCallback();
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage("Registeration failed, Please try again later!");
    }
    setLoading(false);
  };

  const sendOtp = async () => {
    const url = `${USER_API_URL}/v1/genius_hunt/sendotp?countryCode=91&mobileNo=${mobileNumber}`;
    const response = await (await fetch(url)).json();
    if (response?.code == 200) {
      setshowOtpModal(true);
    }
  };

  const verifyOtp = async () => {
    const url = `${USER_API_URL}/v1/genius_hunt/verifyotp?countryCode=91&mobileNo=${mobileNumber}&otp=${otp}`;
    const response = await (await fetch(url)).json();
    if (response?.code == 200) {
      setshowSuccessModal(true);
      setshowOtpModal(false);
    } else {
      setOtpErrorMessage(response?.message);
    }
  };

  const handleSuccessClose = () => {
    setshowSuccessModal(false);
    setName("");
    setEmail("");
    setInternationNumber("");
  };

  return (
    <>
      <section className={`container_elm_2 ${styleCss.registerform}`}>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className={styleCss.img_wrap}>
              <img src={props.img} alt="props_Img" />
            </div>
          </div>
          <div className="col-lg-8">
            <div className={styleCss.formWrap}>
              <div className={styleCss.title_wrap}>
                <h4 className="mb-1">
                  {props.title
                    ? props.title
                    : "Register for the next Math Genius Hunt event at"}
                </h4>
                <h3>Mumbai, July 2022</h3>
              </div>
              <form>
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <div className="form-group">
                      <label htmlFor="name">Name *</label>
                      <input
                        type="text"
                        name=""
                        id="name"
                        className="form-control"
                        placeholder="Enter Your Full Name"
                        value={name}
                        autoComplete="off"
                        maxLength={50}
                        onChange={(e) =>
                          setName(e.target.value.replace(/[^a-z ]/gi, "").replace(/^ +/, '').replace(/ {2}/g, " "))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <div className="form-group">
                      <label htmlFor="Number">Parents Contact Number *</label>
                      <div className="input-group m-0 w-100">
                        <span className="input-group-text" id="basic-addon1">
                          +91
                        </span>
                        <input
                          type="text"
                          name=""
                          id="Number"
                          className="form-control"
                          placeholder=""
                          value={mobileNumber}
                          maxLength={10}
                          autoComplete="off"
                          onInput={(e) => {
                            let inputValue = e.target.value;
                            inputValue = inputValue.replace(/[^0-9]+/g, "");
                            inputValue = inputValue.replace(/^0/, "");
                            inputValue = inputValue.substring(0, 10);
                            setMobileNumber(inputValue);
                            setErrorMessage("");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mb-lg-0 mb-4">
                    <div className="form-group">
                      <label htmlFor="name">Email</label>
                      <input
                        type="text"
                        name=""
                        id="Email"
                        className="form-control"
                        placeholder="Enter Your Email ID"
                        value={email}
                        maxLength={255}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 mb-2">
                    <div className="form-group">
                      <label htmlFor="name">Venue</label>
                      <input
                        type="text"
                        name=""
                        id="Venue"
                        className="form-control"
                        placeholder="NES International School"
                        autoComplete="off"
                        readOnly
                        style={{ backgroundColor: "#fff" }}
                      />
                    </div>
                  </div>
                  <div>
                    {errorMessage && (
                      <div className="text text-danger">{errorMessage}</div>
                    )}
                    {successMessage && (
                      <div className="text text-success">{successMessage}</div>
                    )}
                  </div>
                  <div className={`mt-5 ${isPopup ? "col-lg-12" : "col-lg-6"}`}>
                    <div className={styleCss.btn_wrap}>
                      <div className="btn_particepent" onClick={handleRegister}>
                        {isPopup
                          ? "Register For the Mumbai Event"
                          : "Participate"}
                        {loading && (
                          <img
                            src={"./images/loader.svg"}
                            alt=""
                            style={{ display: "inline-block", width: "20px" }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterForm;
