/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import axios from "axios";
import useSound from "use-sound";
import Wave from "../assets/images/Wave.png";
import logo_unit_1 from "../assets/images/logo_unit_1.svg";
import playbtn from "../assets/images/playbtn.svg";
import share_icons_items from "../assets/images/share_icons_items.svg";
import loadingIcons from "../assets/images/loadingIcons.svg";
import startButtonSound from "../assets/audio/Start_button.mp3";

const StartScreen = (props) => {
  const [showShare, setShowShare] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const [playStartButtonSound] = useSound(startButtonSound, { volume: 0.6 });
  const [requestHeaders, setRequestHeaders] = useState([]);
  const [appUrl, setAppUrl] = useState(
    "https://play.google.com/store/apps/details?id=com.hurix.msvgolive&hl=en_IN&gl=US"
  );

  useEffect(() => {
    document.body.classList.add("homepage");
    getQuestion();
    if (navigator.share) {
      setShowShare(true);
    }
    var ua = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|ipod/.test(ua)) {
      setAppUrl(
        "https://apps.apple.com/in/app/msvgo-learning-app-for-6-12th/id1502069282"
      );
    }
    getBoards();
    if (window.self !== window.top) {
      setIsIframe(true);
    }
  }, []);

  const getBoards = async () => {
    axios
      .get(`https://api.msvgo.com/v1/content/getBoards?country_code=IN`)
      .then((res) => {
        setRequestHeaders(res?.headers);
      });
  };

  const createGameUniqueID = () => {
    if (localStorage.getItem("ligaUserId")) {
      var uuid = localStorage.getItem("ligaUserId");
    } else {
      var dt = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (dt + Math.random() * 16) % 16 | 0;
          dt = Math.floor(dt / 16);
          return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      localStorage.setItem("ligaUserId", uuid);
    }
    return uuid;
  };

  const setLigaStart = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/save-guest-user", {
        guest_token: createGameUniqueID(),
        city: requestHeaders["cloudfront-viewer-city"],
        state: requestHeaders["cloudfront-viewer-country-region-name"],
        state_code: requestHeaders["cloudfront-viewer-country-region"],
        country: requestHeaders["cloudfront-viewer-country"],
      })
      .then((res) => { })
      .catch((err) => {
        // setErrorMessage("Could not connect server. Please try again later.");
      });
  };

  const handleShareClick = () => {
    if (shareLoading) return;
    setShareLoading(true);
    if (navigator.share) {
      let currentUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      let shareTitle = "INTERSCHOOL CHALENGE";
      let shareText = `Discover your mathIQ in 90seconds @ `;
      axios
        .get("/miq_share_img.png", { responseType: "blob" })
        .then(function (response) {
          var file = new File([response.data], "msvgo-liga.png", {
            type: "image/png",
          });
          var filesArray = [file];

          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator
              .share({
                files: filesArray,
                title: shareTitle,
                text: shareText,
                url: currentUrl,
              })
              .catch((error) => console.log("Error sharing", error));
          } else {
            navigator
              .share({
                title: shareTitle,
                text: shareText,
                url: currentUrl,
              })
              .catch((error) => console.log("Error sharing", error));
          }
          setShareLoading(false);
        })
        .catch((error) => setShareLoading(false));
    } else {
      setShareLoading(false);
    }
  };

  // precache api
  const getQuestion = () => {
    axios
      .post(process.env.REACT_APP_API_URL + "/prealgebra-questions", {
        level: 1,
      })
      .then((res) => { });
  };

  return (
    <>
      <div className="col-12 col-lg-4 p-0 px-lg-3 startScreen_wrap">
        <section id="startScreen">
          <div className="startScreen_container">
            <div className="home_header">
              <div className="header_content_wrap">
                <div className="logo_wraper">
                  <img src={logo_unit_1} alt="topbg" />
                  <h5>90 SECONDS CHALLENGE</h5>
                </div>
                {/* <div className="timeer_home_img_wrap"> */}
                {/* <img src={Math_in_90_thumbnail} alt="createtive_img_1" /> */}
                {/* <h5>seconds <br/>
                    Challenge</h5> */}
                {/* <p>#gamesplayed</p> */}
                {/* </div> */}

                {/* <div className="game_payed">
                  {totalGamesPlayed &&
                    totalGamesPlayed.map((gp, i) => {
                      return (
                        <div className="game_payed_item" key={"gp_" + i}>
                          {gp}
                        </div>
                      );
                    })}
                </div> */}
                {/* <div className="slider_text">
                  <h5> 1000+ schools, 50+ cities</h5>
                </div> */}
              </div>
              <h1>What’s your mathIQ?</h1>
              <div className="tagline_wrap">
                Use Your Brain (Pen & Paper optional) No Bots or Calculators
                Please
              </div>
              <div
                className="play_btn_wraper text-center"
                onClick={() => {
                  setLigaStart();
                  playStartButtonSound();
                  props.updateScreen("gameScreen");
                }}
              >
                <img
                  src={playbtn}
                  className="startbtn start"
                  alt="play_now_btn"
                />
              </div>
              <img src={Wave} className="wave_img_1" alt="createtive_img_1" />
            </div>
            {/* Footer Bar */}
            <div className="foore_icons">
              {showShare && (
                <div
                  className="icons_itesm share_social_media share_navigate"
                  data-home="home"
                  onClick={handleShareClick}
                >
                  {shareLoading ? (
                    <img src={loadingIcons} alt="footer_icons_items" />
                  ) : (
                    <img src={share_icons_items} alt="footer_icons_items" />
                  )}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default StartScreen;
