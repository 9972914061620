import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import styleCss from "./PrizeSlider.module.css";

const PrizeSlider = () => {
  return (
    <>
      <section className={`${styleCss.PrizeSliderwrap}`}>
        <h3>Amazing prizes to be won at every event!</h3>

        <Swiper
          slidesPerView={1.6}
          spaceBetween={20}
          centeredSlides={true}
          className={styleCss.Swiper}
          loop={true}
          modules={[Autoplay]}
          autoplay={{ delay: 5000 }}
          breakpoints={{
            640: {
              // slidesPerView: 2,
              // spaceBetween: 20,
              slidesPerView: 2.5,
              spaceBetween: 0,
            },
          }}
        >
          <SwiperSlide>
            <div className={`slideCard ${styleCss.slideCard}`}>
              <img src="./images/Amazon-Echo.png" alt="Tabmin 1" />
              <h6>Runner-up Prize</h6>
              <h5>Amazon Echo</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`slideCard ${styleCss.slideCard}`}>
              <img src="./images/Tabmin 1.png" alt="Tabmin 1" />
              <h6>Grand Prize</h6>
              <h5>Android Tablet</h5>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`slideCard ${styleCss.slideCard}`}>
              <img src="./images/ear_phone.png" alt="ear_phone" />
              <h6>Runner-up Prize</h6>
              <h5>Bluetooth Earphones</h5>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </>
  );
};

export default PrizeSlider;
