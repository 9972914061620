import React from "react";
import styleCss from "./Blog.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/navigation";
import Header from "./Header";
import RegisterForm from "./RegisterForm";
import { Navigation, Pagination } from "swiper";
import BlogCard from "./BlogCard";

const BlogArticle = () => {
  return (
    <>
      <main className="BlogArticle">
        <Header isshowparticipat={false} hideVectore={true} />
        <section className={styleCss.BlogArticle}>
          <div className={styleCss.article_img}>
            <img src="./images/articleblog_img.png" alt="articleblog_img" />
          </div>
          <div className="container">
            <div className={styleCss.content}>
              <h1>Quadratic Equations with Two Imaginary Solutions</h1>
              <h6>July 20. 2022</h6>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos. Praesent
                auctor purus luctus enim egestas, ac scelerisque ante pulvinar.
                Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor
                urna. Curabitur vel bibendum lorem. Morbi convallis convallis
                diam sit amet lacinia. Aliquam in elementum tellus. Curabitur
                tempor quis eros tempus lacinia. Nam bibendum pellentesque quam
                a convallis. Sed ut vulputate nisi. Integer in felis sed leo
                vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat
                ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus
                lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit
                amet magna non ligula vestibulum eleifend. Nulla varius volutpat
                turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed
                nec ante dictum sem condimentum ullamcorper quis venenatis nisi.
                Proin vitae facilisis nisi, ac posuere leo. Nam pulvinar blandit
                velit, id condimentum diam faucibus at. Aliquam lacus nisi,
                sollicitudin at nisi nec, fermentum congue felis. Quisque mauris
                dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae
                mauris nec ante pretium finibus. Donec nisl neque, pharetra ac
                elit eu, faucibus aliquam ligula. Nullam dictum, tellus
                tincidunt tempor laoreet, nibh elit sollicitudin felis, eget
                feugiat sapien diam nec nisl. Aenean gravida turpis nisi,
                consequat dictum risus dapibus a. Duis felis ante, varius in
                neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem
                sit amet cursus. Etiam pulvinar purus vitae justo pharetra
                consequat. Mauris id mi ut arcu feugiat maximus. Mauris
                consequat tellus id tempus aliquet. Vestibulum dictum ultrices
                elit a luctus. Sed in ante ut leo congue posuere at sit amet
                ligula. Pellentesque eget augue nec nisl sodales blandit sed et
                sem. Aenean quis finibus arcu, in hendrerit purus. Praesent ac
                aliquet lorem. Morbi feugiat aliquam ligula, et vestibulum
                ligula hendrerit vitae. Sed ex lorem, pulvinar sed auctor sit
                amet, molestie a nibh. Ut euismod nisl arcu, sed placerat nulla
                volutpat aliquet. Ut id convallis nisl. Ut mauris leo, lacinia
                sed elit id, sagittis rhoncus odio. Pellentesque sapien libero,
                lobortis a placerat et, malesuada sit amet dui. Nam sem sapien,
                congue eu rutrum nec, pellentesque eget ligula. Nunc tempor
                interdum ex, sed cursus nunc egestas aliquet. Pellentesque
                interdum vulputate elementum. Donec erat diam, pharetra nec enim
                ut, bibendum pretium tellus. Vestibulum et turpis nibh. Cras vel
                ornare velit, ac pretium arcu. Cras justo augue, finibus id
                sollicitudin et, rutrum eget metus. Suspendisse ut mauris eu
                massa pulvinar sollicitudin vel sed enim. Pellentesque viverra
                arcu et dignissim vehicula. Donec a velit ac dolor dapibus
                pellentesque sit amet at erat. Phasellus porttitor, justo eu
                ultrices vulputate, nisi mi placerat lectus, sed rutrum tellus
                est id urna. Aliquam pellentesque odio metus, sit amet imperdiet
                nisl sodales eu. Quisque viverra nunc nec vestibulum dapibus.
                Integer nec diam a libero tincidunt varius sed vel odio. Donec
                rutrum dapibus massa, vel tempor nulla porta id. Suspendisse
                vulputate fermentum sem sollicitudin facilisis. Aliquam vehicula
                sapien nec ante auctor, quis mollis leo tincidunt. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Etiam eu turpis
                molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
                fringilla accumsan, risus sem sollicitudin lacus, ut interdum
                tellus elit sed risus. Maecenas eget condimentum velit, sit amet
                feugiat lectus. Class aptent taciti sociosqu ad litora torquent
                per conubia nostra, per inceptos himenaeos. Praesent auctor
                purus luctus enim egestas, ac scelerisque ante pulvinar. Donec
                ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna.
                Curabitur vel bibendum lorem. Morbi convallis convallis diam sit
                amet lacinia. Aliquam in elementum tellus.
              </p>
            </div>
          </div>
        </section>
        <div className="container">
          <section>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              loop={false}
              navigation={true}
              modules={[Navigation]}
              className="blog_slider"
            >
              <SwiperSlide>
                <div className="slide_item">
                  <BlogCard />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide_item">
                  <BlogCard />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide_item">
                  <BlogCard />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide_item">
                  <BlogCard />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide_item">
                  <BlogCard />
                </div>
              </SwiperSlide>
            </Swiper>
          </section>
        </div>
        <section className="recent_heihglightForm">
          <RegisterForm img="images/registerform_img_1.png" />
        </section>
      </main>
    </>
  );
};

export default BlogArticle;
