/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import axios from "axios";
import StartScreen from "./StartScreen";
import GameScreen from "./GameScreen";
import ResultScreen from "./ResultScreen";
import frameFirst from "../assets/images/Frame-first.svg";
import frameSecond from "../assets/images/Frame-second.svg";
import frameThird from "../assets/images/Frame-third.svg";
import starYellow from "../assets/images/star-yellow.svg";
import mathIQ_logo from "../assets/images/mathIQ_logo.svg";

function Home() {
  const [minHeight, setMinHeight] = useState(0);
  const [score, setScore] = useState(0);
  const [screen, setScreen] = useState("startScreen");
  const [leaderboard, setLeaderboard] = useState([]);
  const [formatedScore, setFormatedScore] = useState(
    (99 + (14 * (0 - 48)) / 34).toFixed(0)
  );

  let resizeWindow = () => {
    setMinHeight(window.innerHeight);
  };

  useEffect(() => {
    // getLeaderboard();
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    setFormatedScore((99 + (14 * (parseInt(score) - 48)) / 34).toFixed(0));
  }, [score]);

  const getLeaderboard = () => {
    axios
      .post("https://liga-api.msvgo.com/getleaderboarddata-v2", {})
      .then((res) => {
        if (res.data.response) {
          setLeaderboard(res.data.response.slice(0, 8));
        }
      });
  };

  const updateScreen = (no) => {
    setScreen(no);
  };

  let component;
  switch (screen) {
    case "startScreen":
      component = <StartScreen updateScreen={updateScreen} />;
      break;
    case "gameScreen":
      component = (
        <GameScreen updateScreen={updateScreen} setScore={setScore} />
      );
      break;
    case "resultScreen":
      component = <ResultScreen updateScreen={updateScreen} score={score} />;
      break;
    default:
      component = <StartScreen updateScreen={updateScreen} />;
      break;
  }

  return (
    <div className="homepage">
      <main className={screen}>
        <div className="row m-0 mainBg">
          <div className="col-12 p-0">
            <div
              className="row main-container"
              style={{ minHeight: minHeight }}
            >
              <div className="offset-lg-1 col-lg-3 align-self-center p-0 d-none d-lg-block">
                <div className="row liga-container1 py-5" hidden>
                  <div className="bg-full-glass"></div>
                  <div className="col-12 p-0 mark-distribution">
                    <div className="row m-0 justify-content-center">
                      <img src={mathIQ_logo} width="55%" className="mb-3" />
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore > 160 ? "your-score-range-bg" : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p style={{ fontWeight: 700 }}>160+</p>
                      </div>
                      <div className="col-8 p-0">
                        <p style={{ fontWeight: 700 }}>The Next Einstein!</p>
                      </div>
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore >= 130 && formatedScore <= 159
                          ? "your-score-range-bg"
                          : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p>130 - 159</p>
                      </div>
                      <div className="col-8 p-0">
                        <p>A Budding Genius!</p>
                      </div>
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore >= 109 && formatedScore <= 129
                          ? "your-score-range-bg"
                          : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p>109 - 129</p>
                      </div>
                      <div className="col-8 p-0">
                        <p>A Master of Numbers!</p>
                      </div>
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore >= 93 && formatedScore <= 108
                          ? "your-score-range-bg"
                          : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p>93 - 108</p>
                      </div>
                      <div className="col-8 p-0">
                        <p>Better Than Most!</p>
                      </div>
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore >= 82 && formatedScore <= 92
                          ? "your-score-range-bg"
                          : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p>82 - 92</p>
                      </div>
                      <div className="col-8 p-0">
                        <p>Capable of Doing Better!</p>
                      </div>
                    </div>
                    <div
                      className={`row m-0 px-2 ${
                        formatedScore < 82 ? "your-score-range-bg" : ""
                      }`}
                    >
                      <div className="col-4 p-0">
                        <p>&#60;82</p>
                      </div>
                      <div className="col-8 p-0">
                        <p>A Sleepy Head!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {component}

              <div className="col-lg-3 align-self-center liga-container3_wrap">
                <div className="row liga-container3" hidden>
                  <div className="col-12 p-0">
                    <div className="bg-full-glass"></div>
                    <div className="row m-0 liga-leaderboard-header">
                      <div style={{ fontSize: "12px" }}>
                        <p className="m-0">Leaderboard</p>
                      </div>
                      <div className="mathIQ-logo">
                        <p className="m-0">
                          math<span>IQ</span>
                        </p>
                      </div>
                    </div>
                    <div className="row m-0 pt-4 liga-leaderboard-data">
                      <div className="col-12 p-0">
                        {leaderboard &&
                          leaderboard.map((leader, i) => {
                            return (
                              <div className="row m-0 mb-3" key={"li_" + i}>
                                <div className="col-2 p-0 text-right">
                                  {i === 0 && (
                                    <img
                                      src={frameFirst}
                                      style={{ width: "40%" }}
                                    />
                                  )}
                                  {i === 1 && (
                                    <img
                                      src={frameSecond}
                                      style={{ width: "40%" }}
                                    />
                                  )}
                                  {i === 2 && (
                                    <img
                                      src={frameThird}
                                      style={{ width: "40%" }}
                                    />
                                  )}
                                  {i > 2 && (
                                    <p className="stu_rank">#{i + 1}</p>
                                  )}
                                </div>
                                <div className="col-7 p-0 pl-1">
                                  <p className="school-name m-0">
                                    {leader?.school_name}
                                  </p>
                                  <p className="stu_name-city">
                                    {leader?.star_player} - {leader?.city}
                                  </p>
                                </div>
                                <div className="col-3 p-0">
                                  <p className="school-rate">
                                    {leader?.score}{" "}
                                    <img
                                      src={starYellow}
                                      style={{ width: "7px" }}
                                      className="ml-1"
                                    />
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Home;
