/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import StartScreen from "./StartScreen";
import GameScreen from "./GameScreen";
import ResultScreen from "./ResultScreen";
import AppDownload from "../common/AppDownload";
import frameFirst from "../../assets/images/Frame-first.svg";
import frameSecond from "../../assets/images/Frame-second.svg";
import frameThird from "../../assets/images/Frame-third.svg";
import starYellow from "../../assets/images/star-yellow.svg";
import geniusimg from "../../assets/images/msvgo_genius.svg";
import yellow_img from "../../assets/images/yellow_img.png";
import green_img from "../../assets/images/green_img.png";
import light_green_img from "../../assets/images/light_green_img.png";

const USER_API_URL = "https://api.msvgo.com";

const parseJwt = (token) => {
  try {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return {};
  }
};

function GeniusHunt(props) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [minHeight, setMinHeight] = useState(0);
  const [score, setScore] = useState(0);
  const [screen, setScreen] = useState("startScreen");
  const [leaderboard, setLeaderboard] = useState([]);
  const [showPasscodeModal, setShowPasscodeModal] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [grade, setGrade] = useState("");
  const [user, setUser] = useState({});
  const [society, setSociety] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [formatedScore, setFormatedScore] = useState(
    (99 + (14 * (0 - 48)) / 34).toFixed(0)
  );

  let resizeWindow = () => {
    setMinHeight(window.innerHeight);
  };

  useEffect(() => {
    getSociety();
    getLeaderboard();
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    setFormatedScore((99 + (14 * (parseInt(score) - 48)) / 34).toFixed(0));
  }, [score]);

  const getSociety = () => {
    axios
      .post(`${USER_API_URL}/v1/genius_hunt/get_society`, { name: slug })
      .then((res) => {
        if (res.data.code === 200) {
          setSociety(res.data.data);
          setShowPasscodeModal(true);
        } else {
          navigate("/");
        }
      });
  };

  const getLeaderboard = () => {
    axios
      .get(`${USER_API_URL}/v1/genius_hunt/leaderboard?name=${slug}`)
      .then((res) => {
        if (res.data.data) {
          setLeaderboard(res.data.data.slice(0, 8));
        }
      });
  };

  const verifyPasscode = () => {
    if (!passcode) {
      setErrorMessage("Please enter passcode");
      return;
    }
    if (!grade) {
      setErrorMessage("Please select grade");
      return;
    }
    axios
      .get(
        `${USER_API_URL}/v1/genius_hunt/verify_passcode?pass_code=${passcode}&grade=${grade}`
      )
      .then((res) => {
        if (res.data.code === 200) {
          setUser(parseJwt(res.data.token));
          setShowPasscodeModal(false);
        } else {
          setErrorMessage(res.data.message);
        }
      });
  };

  const checkStatus = () => {
    if (user?.id) {
      setScreen("gameScreen");
    } else {
      setShowPasscodeModal(true);
    }
  };

  const updateScreen = (no) => {
    if (no === "resultScreen") {
      getLeaderboard();
    }
    setScreen(no);
  };

  let component;
  switch (screen) {
    case "startScreen":
      component = (
        <StartScreen
          updateScreen={updateScreen}
          checkStatus={checkStatus}
          user={user}
        />
      );
      break;
    case "gameScreen":
      component = (
        <GameScreen
          updateScreen={updateScreen}
          setScore={setScore}
          user={user}
          society={society}
        />
      );
      break;
    case "resultScreen":
      component = (
        <ResultScreen updateScreen={updateScreen} score={score} user={user} />
      );
      break;
    default:
      component = <StartScreen updateScreen={updateScreen} />;
      break;
  }

  return (
    <>
      <div className="homepage">
        <main className={screen}>
          <div className="row m-0 mainBg">
            <div className="col-12 p-0">
              <div
                className="row main-container"
                style={{ minHeight: minHeight }}
              >
                <div className="offset-lg-1 col-lg-3 align-self-center p-0 d-none d-lg-block">
                  <AppDownload />
                </div>

                {component}

                <div className="col-lg-3 align-self-center liga-container3_wrap">
                  <div className="row liga-container3">
                    <div className="col-12 p-0">
                      <div className="bg-full-glass"></div>
                      <div className="row m-0 liga-leaderboard-header">
                        <div style={{ fontSize: "12px" }}>
                          <p className="m-0">Leaderboard</p>
                        </div>
                        <div className="mathIQ-logo">
                          <p className="m-0">
                            math<span>IQ</span>
                          </p>
                        </div>
                      </div>
                      <div className="row m-0 pt-4 liga-leaderboard-data">
                        <div className="col-12 p-0">
                          {leaderboard &&
                            leaderboard.map((leader, i) => {
                              return (
                                <div className="row m-0 mb-3" key={"li_" + i}>
                                  <div className="col-2 p-0 text-right">
                                    {i === 0 && (
                                      <img
                                        src={frameFirst}
                                        style={{ width: "40%" }}
                                      />
                                    )}
                                    {i === 1 && (
                                      <img
                                        src={frameSecond}
                                        style={{ width: "40%" }}
                                      />
                                    )}
                                    {i === 2 && (
                                      <img
                                        src={frameThird}
                                        style={{ width: "40%" }}
                                      />
                                    )}
                                    {i > 2 && (
                                      <p className="stu_rank">#{i + 1}</p>
                                    )}
                                  </div>
                                  <div className="col-7 p-0 pl-1">
                                    <p className="school-name m-0">
                                      {leader?.user_name}
                                    </p>
                                  </div>
                                  <div className="col-3 p-0">
                                    <p className="school-rate">
                                      {(
                                        99 +
                                        (14 * (leader?.max_score - 48)) / 34
                                      ).toFixed(0)}{" "}
                                      <img
                                        src={starYellow}
                                        style={{ width: "7px" }}
                                        className="ml-1"
                                      />
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        id="userdetails"
        size="md"
        centered
        show={showPasscodeModal}
        onHide={() => setShowPasscodeModal(false)}
        dialogClassName={"passcodeModal"}
      >
        <Modal.Body>
          <img
            src={yellow_img}
            alt="yellow_img"
            className="yellow_img vecter_img"
          />
          <img
            src={green_img}
            alt="green_img"
            className="green_img vecter_img"
          />
          <img
            src={light_green_img}
            alt="light_green_img"
            className="light_green_img vecter_img"
          />
          <div className="model_head text-center">
            <img src={geniusimg} alt="geniusimg" />
            <h4>Input your Game Pass Code & Grade to Begin</h4>
          </div>
          <div className="otp_input pt-3">
            <div className="form-group">
              <label className="otp_label">
                Game Pass Code <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="passcode"
                id="otp"
                placeholder="Enter Your Game Pass Code"
                onInput={(e) => setPasscode(e.target.value)}
              />
              <span className="help-block">
                Your Game Code is sent to your mobile number
              </span>
            </div>
            <div className="form-group">
              <label className="otp_label">
                Grade <span className="required">*</span>
              </label>
              <select
                className="form-control"
                name="grade"
                id="grade"
                onChange={(e) => setGrade(e.target.value)}
              >
                <option value="">Select Grade</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
            {errorMessage && (
              <div className="text-center text-danger">{errorMessage}</div>
            )}
            <div className="text-center d-flex align-items-center py-5">
              <div
                className="btn btn-light btn-block mr-2"
                onClick={() => setShowPasscodeModal(false)}
              >
                Cancel
              </div>
              <div
                className="btn btn-primary btn-block m-0 ml-2"
                onClick={verifyPasscode}
              >
                Verify and Proceed
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GeniusHunt;
