/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import mathIQ_logo from "../assets/images/mathIQ_logo.svg";
import backbtn from "../assets/images/backbtn.png";
import logo_unit from "../assets/images/logo_unit.svg";
import restart_icon from "../assets/images/restart_icon.svg";
import skip_icon from "../assets/images/skip_icon.svg";
// import errorLogo from "../assets/images/oops_icon.svg";
import useSound from "use-sound";
import correctSound from "../assets/audio/correct.mp3";
import wrongSound from "../assets/audio/incorrect.mp3";
import skipSound from "../assets/audio/skip.mp3";
import timerSound from "../assets/audio/Timer_0.mp3";

const GameScreen = (props) => {
  const defaultLevel = 1;
  const minLevel = 1;
  const maxLevel = 8;
  const totalSeconds = 90;
  const streakCount = 3;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [level, setLevel] = useState(defaultLevel);
  const [seconds, setSeconds] = useState(totalSeconds);
  const [strokeWidth, setStrokeWidth] = useState(0);
  const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
  const [wrongAnswerCount, setWrongAnswerCount] = useState(0);
  const [correctStreakCount, setCorrectStreakCount] = useState(0);
  const [wrongStreakCount, setWrongStreakCount] = useState(0);
  const [score, setScore] = useState(0);
  const [formatedScore, setFormatedScore] = useState(
    (99 + (14 * (0 - 48)) / 34).toFixed(0)
  );
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState({});
  const [questionNo, setQuestionNo] = useState(0);
  const [questionOriginal, setQuestionOriginal] = useState({});
  const [answerArray, setAnswerArray] = useState([]);
  const [startTime, setStartTime] = useState(Date.now());
  const [playCorrectSound] = useSound(correctSound);
  const [playWrongSound] = useSound(wrongSound);
  const [playSkipSound] = useSound(skipSound);
  const [playTimerSound] = useSound(timerSound);
  // const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    document.body.classList.remove("homepage");
    getQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFormatedScore((99 + (14 * (parseInt(score) - 48)) / 34).toFixed(0));
  }, [score]);

  const getQuestions = (qusetionLevel) => {
    axios
      .get(
        "https://n1n03k9cph.execute-api.ap-south-1.amazonaws.com/STAG/daily-50"
      )
      .then((res) => {
        setQuestions(res.data.body);
        setQuestion(res.data.body[0]);
        setQuestionNo((q) => q + 1);
      });
  };

  const getQuestion = (qusetionLevel) => {
    setQuestion(questions[questionNo]);
    setQuestionNo((q) => q + 1);
    clearUIClass();
    setIsLoading(false);
    setStartTime(Date.now());
  };

  const checkAnswer = (i, answer) => {
    if (isLoading) return;
    setIsLoading(true);
    document.getElementById("option_" + i).classList.add("addpauseGray");

    let tempLevel = level;
    if (question.CorrectAnswer === answer) {
      document.getElementById("option_" + i).classList.add("correctColor");
      playCorrectSound();
      setCorrectAnswerCount(correctAnswerCount + 1);
      setScore(parseInt(score) + parseInt(question.CorrectP));
      if (correctStreakCount === streakCount - 1) {
        tempLevel++;
        if (tempLevel > maxLevel) tempLevel = maxLevel;
        setLevel(tempLevel);
        setCorrectStreakCount(0);
      } else {
        setCorrectStreakCount(correctStreakCount + 1);
      }
      setWrongStreakCount(0);
    } else {
      document.getElementById("option_" + i).classList.add("IncorrectColor");
      playWrongSound();
      setWrongAnswerCount(wrongAnswerCount + 1);
      setScore(parseInt(score) + parseInt(question.IncorrectP));
      if (wrongStreakCount === streakCount - 1) {
        tempLevel--;
        if (tempLevel < minLevel) tempLevel = minLevel;
        setLevel(tempLevel);
        setWrongStreakCount(0);
      } else {
        setWrongStreakCount(wrongStreakCount + 1);
      }
      setCorrectStreakCount(0);
    }
    setTimeout(() => {
      getQuestion(tempLevel);
    }, 500);
  };

  const createGameUniqueID = () => {
    var dt = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const sumbitQuiz = () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    axios
      .post(process.env.REACT_APP_API_URL + "/save-score", {
        totalScore: score,
        gameID: "prealgebra",
        gameUniqueID: createGameUniqueID(),
        userID: "",
        "user-initials": "guest",
        mobile: "",
        userResponse: answerArray,
      })
      .then((res) => {
        props.updateScreen("resultScreen");
      })
      .catch((err) => {
        // setErrorMessage("Could not connect server. Please try again later.");
      });
  };

  const saveAnswer = (status, UserAnswer) => {
    let answerObj = {
      level,
      isQuestionSkipped: status === null ? true : false,
      QuestionText: questionOriginal?.QuestionText,
      isCorrect: status,
      CorrectAnswer: null,
      UserAnswer,
      Score: score,
      responseTime: Date.now() - startTime,
      FourOptions: questionOriginal?.FourOptions,
    };
    setAnswerArray([...answerArray, ...[answerObj]]);
  };

  const clearUIClass = () => {
    try {
      for (let i = 0; i < 4; i++) {
        document.getElementById("option_" + i).classList.remove("addpauseGray");
        document.getElementById("option_" + i).classList.remove("correctColor");
        document
          .getElementById("option_" + i)
          .classList.remove("IncorrectColor");
      }
    } catch (e) {}
  };

  const handleReset = () => {
    setSeconds(totalSeconds);
    setCorrectAnswerCount(0);
    setWrongAnswerCount(0);
    setWrongStreakCount(0);
    setCorrectStreakCount(0);
    setLevel(defaultLevel);
    setScore(0);
    setAnswerArray([]);
    setQuestion(questions[0]);
    setQuestionNo(1);
    clearUIClass();
    setIsLoading(false);
    setStartTime(Date.now());
  };

  const handleSkip = () => {
    saveAnswer(null, null);
    playSkipSound();
    getQuestion(level);
  };

  // const closeErrorPopup = () => {
  //   clearUIClass();
  //   handleReset();
  //   setErrorMessage(null);
  // };

  return (
    <>
      {/* <!-- container 1 --> */}
      <div className="offset-lg-1 col-lg-3 align-self-center p-0 d-none d-lg-block"></div>

      {/* <!-- container 2 --> */}
      <div className="col-12 col-lg-4 p-0 px-lg-3 align-self-center">
        <div id="quizScreen" className="row">
          <div className="col-12 flex-column d-flex justify-content-between p-0">
            <div className="topbrand">
              <div className="text-center d-flex justify-content-center logo_wrap ">
                <div className="content_wraper">
                  <img
                    src={logo_unit}
                    className="top_bg_img_desck"
                    alt="topbg"
                  />
                </div>
              </div>
            </div>

            <div className="scorebase">
              <div className="timeAndScoreMain">
                <div className="timmer" style={{ background: "none" }}>
                  <div className="timmer_wrap">
                    {questionNo < questions.length && (
                      <strong className="counterTimer">{questionNo}</strong>
                    )}
                    {/* <span>Question</span> */}
                  </div>
                  <div className="wrapper">
                    <svg>
                      <circle
                        style={{ strokeDashoffset: strokeWidth }}
                        id="circle_1"
                        cx="20"
                        cy="20"
                        r="20"
                      ></circle>
                    </svg>
                  </div>
                </div>
                <div className="counter_text">
                  <div className="correct_incorrect">
                    <div className="correctCount">{correctAnswerCount}</div>
                    <div className="incorrectCount">{wrongAnswerCount}</div>
                  </div>
                  <div className="scoreMain d-none">
                    <div className="scoreText">Score</div>
                    <div className="scoreCounter">{score}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 flex-column d-flex justify-content-between">
            <div className="instText">{question?.QuestionVal}&nbsp;</div>
          </div>
          <div className="col-12 flex-column d-flex justify-content-between ">
            <div className="question col-sm" id="question">
              <div
                id="optiontext"
                dangerouslySetInnerHTML={{
                  __html: question?.QuestionText,
                }}
              ></div>
              {questionNo >= questions.length + 1 &&
                !question &&
                "Daily49 Challenge Completed"}
            </div>
          </div>
          <div className="col-12 flex-column d-flex justify-content-between mt-4 optio_container">
            <div className="container-fluid">
              <div className="row m-0">
                {question?.FourOptions &&
                  question?.FourOptions.map((option, i) => {
                    return (
                      <div
                        className="col-6  pr-sm-1 pl-0 pr-2 pb-1 optMainH"
                        key={"ot_" + i}
                        onClick={() => checkAnswer(i, option)}
                      >
                        <div
                          className="options option_A option option-unclick"
                          id={"option_" + i}
                          dangerouslySetInnerHTML={{ __html: option }}
                        ></div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="container-fluid p-0 mt-3">
            <div className="row pt-0 pb-3 p-0">
              <div className="col-6 text-center">
                <img
                  src={restart_icon}
                  className="restartbtn ml-5"
                  id="restart"
                  alt="restart"
                  onClick={handleReset}
                />
              </div>
              <div className="col-6 text-center">
                {questionNo < questions.length && (
                  <img
                    src={skip_icon}
                    className="skipbtn mr-5"
                    id="skip"
                    alt="skip"
                    onClick={handleSkip}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameScreen;
