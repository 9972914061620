import Header from "./Header";
import MathiqFeature from "./MathiqFeature";
import MathiqGame from "./MathiqGame";
import NextCity from "./NextCity";
import PrizeSlider from "./PrizeSlider";
import RecentHeighlights from "./RecentHeighlights";
import RegisterForm from "./RegisterForm";
import Footer from "./Footer";

function App() {
  return (
    <main className="geniushuntApp">
      <Header isshowparticipat={true} />
      <RecentHeighlights />
      <RegisterForm img="images/registerform_img_1.png" />
      <PrizeSlider />
      <MathiqFeature />
      <MathiqGame />
      <section className="RegisterForm_container">
        <RegisterForm
          img="images/registerform_img_2.png"
          title="Get ready for the next Math Genius Hunt event at"
        />
      </section>
      <NextCity />
      <Footer />
    </main>
  );
}

export default App;
