import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styleCss from "./viewmoreMobileSlider.module.css";
import ModelPopup from "./ModelPopup";
const ViewmoreMobileSlider = () => {
  const [imgPopup, setimgPopup] = useState(false);
  const [imgPopupImag, setimgPopupImag] = useState("");
  const handleShowimgPopup = (e) => {
    setimgPopupImag(e.target.src);

    setimgPopup(true);
  };
  const handelCloseimgPopup = (child) => setimgPopup(child);
  return (
    <>
      <div className={styleCss.viewmoreMobileSlider}>
        <Swiper
          slidesPerView={1.2}
          spaceBetween={20}
          centeredSlides={true}
          className={styleCss.Swiper}
          loop={true}
        >
          <SwiperSlide>
            <div className={` ${styleCss.slideCard}`}>
              <img
                src="./images/gal_4.png"
                alt="gal_1"
                onClick={handleShowimgPopup}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${styleCss.slideCard}`}>
              <img
                src="./images/gal_6 (2).png"
                alt="gal_2"
                onClick={handleShowimgPopup}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${styleCss.slideCard}`}>
              <img
                src="./images/gal_3.png"
                alt="gal_3"
                onClick={handleShowimgPopup}
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${styleCss.slideCard}`}>
              <img
                src="./images/gal_6.png"
                alt="gal_3"
                onClick={handleShowimgPopup}
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="imgpopoup_wrap">
        <ModelPopup
          show={imgPopup}
          handelFromParent={handelCloseimgPopup}
          className="imgpopup"
          backdropClassName="imgpopupBackgroup"
        >
          <img src={imgPopupImag} alt="imgPopupImag" />
        </ModelPopup>
      </div>
    </>
  );
};

export default ViewmoreMobileSlider;
