import React, { useState, useEffect } from "react";
import stylecss from "./Header.module.css";
import ModelPopup from "./ModelPopup";
import RegisterForm from "./RegisterForm";
import Typewriter from "typewriter-effect";

const Header = (props) => {
  const [show, setShow] = useState(false);
  const [Showmenu, setShowmenu] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const handelClose = (child) => setShow(child);
  const handelsetShowmenu = () => {
    setShowmenu(!Showmenu);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!sessionStorage.getItem("hidePopup") && !show) {
        setShow(true);
      }
    }, 35000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const registerCallback = () => {
    sessionStorage.setItem("hidePopup", isChecked);
    isChecked && handelClose();
  };

  return (
    <>
      <div className="container_elm_2">
        <nav>
          <div className={stylecss.log_wrap}>
            <a href="/genius-hunt">
              <img
                src="./images/geniushundimg_log.svg"
                alt="geniushundimg_log"
              />
            </a>
          </div>
          <div className={stylecss.link_menu_wrap}>
            <div className={stylecss.toggleButton} onClick={handelsetShowmenu}>
              <img src="./images/menuImg.png" alt="menuImg" />
            </div>
            <div
              className={`${stylecss.link_menu} ${
                Showmenu ? stylecss.active : ""
              }`}
            >
              <div className={stylecss.close_btn} onClick={handelsetShowmenu}>
                <img src="./images/Cancel.png" alt="Cancel" />
              </div>
              <a href="https://www.mathiq.org">MathIQ</a>

              <a href="https://msvgo.com">msvgo</a>

              <a href="/recent-highlights">Past Events</a>
            </div>
          </div>
          {props.hideVectore ? (
            ""
          ) : (
            <img
              src="./images/Rectangle 4239.png"
              className={stylecss.rectVecter}
              alt="Rectangle"
            />
          )}
        </nav>
      </div>
      {props.isshowparticipat && (
        <div className="container_elm">
          <div className={stylecss.geniushunt_flyer}>
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className={stylecss.content}>
                  <h2>
                    We are looking for <br /> India’s next
                  </h2>
                  <div className={stylecss.typewrite}>
                    <h3>
                      <Typewriter
                        options={{
                          strings: [
                            "Ramanujam.",
                            "Mathemagician",
                            "Math Genius",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </h3>
                  </div>

                  <p>
                    Test your Math IQ, and find out if <br /> you can take the
                    crown.
                  </p>
                  <div
                    className="btn_particepent"
                    onClick={() => setShow(true)}
                  >
                    Participate
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={stylecss.geniushuntVecter_img_wrap}>
                  <img
                    src="./images/vecter_img.png"
                    alt="vecter_img"
                    className={stylecss.vecter_img}
                  />
                  <img
                    src="./images/devide_vector_icon.png"
                    alt="devide_vector_icon"
                    className={stylecss.devide_vector_icon}
                  />

                  <div className={stylecss.name_tag}>
                    <div className={stylecss.name_tag_elm}>
                      Sanchit Sahukar <br />
                      <span>Class 9</span>
                    </div>
                    <div className={stylecss.subject_rank}>Math IQ - 137</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.isshowparticipat && (
        <ModelPopup show={show} handelFromParent={handelClose}>
          <div className="registerPopup">
            <RegisterForm
              img="images/registerform_img_1.png"
              isPopup={true}
              registerCallback={registerCallback}
            />
            <div className="row">
              <div className="col-lg-8 offset-lg-4 col-12">
                <div className="form-check p-0 mt-3">
                  <label className="form-check-label">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name=""
                      id=""
                      value="checkedValue"
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        sessionStorage.setItem("hidePopup", e.target.checked);
                      }}
                    />
                    Do not show this popup again
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ModelPopup>
      )}
    </>
  );
};

export default Header;
