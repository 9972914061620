import React, { useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import NextCity from "./NextCity";
import RegisterForm from "./RegisterForm";
import Footer from "./Footer";
import Header from "./Header";
const ViewmoreRecentHeighlights = () => {
  const videoElement = useRef(null);
  const [showplaybth, setshowplaybth] = useState(true);

  const videoPlay = () => {
    setshowplaybth(!showplaybth);
    showplaybth ? videoElement.current.play() : videoElement.current.pause();
  };
  const videoStop = (isStop) => {
    videoElement.current.pause();
    setshowplaybth(isStop);
  };

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(true);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }
  let isInViewport = useIsInViewport(videoElement);

  useEffect(() => {
    if (!isInViewport) {
      videoStop(!isInViewport);
    }
  }, [isInViewport]);
  return (
    <main className="geniushuntApp recent_heighlight_page">
      <Header isshowparticipat={false} />
      <section className="recent_heighlight_header container_elm_2">
        <div className="video_player">
          <h1>Recent Highlights</h1>
          <div className="video_wrap_elm">
            {showplaybth && (
              <div className="btn_play_video" onClick={videoPlay}>
                <img src="./images/playvideo_btn.png" alt="playvideo_btn" />
              </div>
            )}
            {!showplaybth && (
              <div className="btn_play_video" onClick={videoPlay}>
                <img src="./images/pouse_btn.png" alt="pouse_btn" />
              </div>
            )}
            <video poster="./images/videoposter.png" ref={videoElement}>
              <source src="./videos/event_video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="vectore_icons_elm">
            <img
              src="./images/plus_icon.png"
              className="plus_icon"
              alt="plus_icon"
            />
            <img
              src="./images/divi_icons.png"
              className="divi_icons"
              alt="plus_icon"
            />
            <img
              src="./images/circle_icon.png"
              className="circle_icon"
              alt="plus_icon"
            />
          </div>
        </div>
        <div className="event_plan">
          <div className="row">
            <div className="col-lg-2 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="content">
                <img
                  src="./images/geniushundimg_log.svg"
                  alt="geniushundimg_log"
                />
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="content">
                <h5>
                  <span>Venue:</span>
                  Runwal Seagull Apt., Pune
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 mb-lg-0 mb-4">
              <div className="content">
                <h5>
                  <span>Date:</span>
                  May, 2022
                </h5>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-12 mb-lg-0">
              <div className="content">
                <h5>
                  <span>Participants:</span>
                  120
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="masterofmath">
        <div className=" container_elm_2">
          <div className="masterofmath_wrap">
            <div className="row">
              {/* <div className="col-sm-5">
                <div className="crad_img_wrap">
                  <img src="./images/masterofmath.png" alt="masterofmath" />
                </div>
              </div> */}
              <div className="col-12">
                <div className="slider_wrap">
                  <Swiper
                    loop={true}
                    spaceBetween={8}
                    slidesPerView={3}
                    invert={true}
                    loopedSlides={6}
                    navigation={true}
                    modules={[Navigation]}
                    centeredSlides={true}
                    breakpoints={{
                      600: {
                        slidesPerView: 4,
                        centeredSlides: false,
                      },
                    }}
                  >
                    <SwiperSlide>
                      <div className="slide_img">
                        <img src="./images/stu_1.png" alt="stu_1" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slide_img">
                        <img src="./images/stu_2.png" alt="stu_2" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slide_img">
                        <img src="./images/stu_3.png" alt="stu_1" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="slide_img">
                        <img src="./images/stu_2.png" alt="stu_2" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="content">
                    <h4>Pune Event Winners</h4>
                    <div className="student_name">
                      <h5>
                        Aarav Basutakar <br />
                        3rd Prize
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="recent_heihglightForm">
        <RegisterForm img="images/registerform_img_1.png" />
      </section>
      <section className="getting_started">
        <div className="getting_started_wrap">
          <div className="container_elm_2">
            <div className="row flex-sm-row flex-column-reverse align-items-end">
              <div className="col-md-6 pe-lg-3">
                <div className="crad_img_wrap ">
                  <img src="./images/getstarted_1.png" alt="getstarted_1" />
                </div>
              </div>
              <div className="col-md-6 ps-lg-3">
                <div className="content">
                  <h3 className="mb-lg-4 mb-3">Getting Started</h3>
                  <p className="mb-lg-5 mb-4">
                    In the summer of 2022 our Genius Hunt took Pune by storm!
                    Hordes of students (200+) gathered to challenge themselves &
                    each other in an exciting number-crunching, lightning-fast
                    game of Maths. The stakes were high. The competition was
                    stiff. And the commitment was inspiring!
                  </p>
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="crad_img_wrap pe-lg-2">
                        <img
                          src="./images/getstarted_2.png"
                          alt="masterofmath"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="crad_img_wrap ps-lg-2">
                        <img
                          src="./images/getstarted_3.png"
                          alt="masterofmath"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gameon_wrap my-3 py-sm-3">
              <div className="row">
                <div className="col-md-6 d-bock d-flex flex-sm-column flex-column-reverse">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="crad_img_wrap pe-lg-2">
                        <img src="./images/gameon_1.png" alt="gameon_1" />
                      </div>
                    </div>
                  </div>
                  <div className="content mb-sm-0 mb-4">
                    <h3 className="mt-lg-3 mt-sm-3">Game On!</h3>
                    <p className="mt-lg-3 mt-sm-3">
                      Ignoring the urge to spend their vacation time elsewhere,
                      these Champs dedicated two whole days to honing their
                      maths skills. The neck-in-neck competition had parents
                      cheering on their youngsters; barely able to contain their
                      own excitement. Friends and neighbours in the vicinity
                      joined in on the action.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 ps-lg-3">
                  <div className="crad_img_wrap">
                    <img src="./images/gameon_2.png" alt="gameon_2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="prizegoseto_wrap my-3 py-sm-3 ">
          <div className="container_elm_2">
            <div className="row flex-sm-row flex-column-reverse">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="crad_img_wrap pe-lg-2">
                      <img src="./images/price_1.png" alt="price_1" />
                    </div>
                  </div>
                  <div className="col-md-6 ">
                    <div className="crad_img_wrap ps-lg-2">
                      <img src="./images/price_2.png" alt="price_2" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-sm-0 mt-4">
                <div className="content">
                  <h3 className="mb-lg-3 mb-3">Some Stood Out! </h3>
                  <p className="mb-lg-3 mb-3">
                    From this almost electric frenzy, a calm emerged. A calm, in
                    the form of our unshakable winners; Sanchit Goyal, WINNER 2
                    and WINNER 3. Sanchit’s score of 137 was in the top 1% of
                    ALL our app users. We were blown away!
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-sm-5 align-items-center">
              <div className="col-md-3 ">
                <div className="crad_img_wrap pe-lg-2">
                  <img src="./images/price_3.png" alt="price_1" />
                </div>
              </div>
              <div className="col-md-6 ">
                <div className="crad_img_wrap ps-lg-2">
                  <img src="./images/price_4.png" alt="price_4" />
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="crad_img_wrap ps-lg-2">
                  <img src="./images/price_5.png" alt="price_5" />
                </div>
              </div>
            </div>
          </div>
          <div />
          <div className="container_elm_2">
            <div className="gameon_wrap my-3 py-sm-3">
              <div className="row align-items-end">
                <div className="col-md-6">
                  <div className="content">
                    <h3 className="mb-lg-3 mb-3">The Mind-Blown Team </h3>
                    <p className="mb-lg-3 mb-4">
                      The energy displayed by our Champs, truly rubbed off on
                      the team. Every one of us had a blast engaging with and
                      gaining insights from the participants. Conversations
                      didn’t seem to cease and we couldn’t have been happier!
                      We’re looking forward to the next Genius Hunt, and you?
                    </p>
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="crad_img_wrap pe-lg-2">
                          <img src="./images/team_2.png" alt="team_2" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ps-lg-3">
                  <div className="crad_img_wrap ">
                    <img src="./images/team_1.png" alt="team_1" />
                  </div>
                </div>
              </div>
              <div className="row mt-sm-3 pt-sm-3 pe-md-3">
                <div className="col-md-8">
                  <div className="crad_img_wrap ">
                    <img src="./images/team_3.png" alt="team_3" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="crad_img_wrap">
                    <img src="./images/team_4.png" alt="team_4" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="RegisterForm_container">
        <RegisterForm
          img="images/registerform_img_2.png"
          title="Get ready for the next Math Genius Hunt event at"
        />
      </section>
      <NextCity />
      <Footer />
    </main>
  );
};

export default ViewmoreRecentHeighlights;
