import React from "react";
import styleCss from "./Blog.module.css";
import BlogCard from "./BlogCard";
import Header from "./Header";
import RegisterForm from "./RegisterForm";

const Blog = () => {
  return (
    <>
      <main className="blog">
        <Header isshowparticipat={false} hideVectore={true} />
        <section className={styleCss.header_section}>
          <div className="container_elm_2">
            <div className="row align-items-center">
              <div className="col-md-6 col-12">
                <div className={styleCss.content}>
                  <h1>MATH IQ: Improves focus on numericals</h1>
                  <p>
                    Ability to solve problems on the go. Ranging from simple to
                    complex equations, we can help not only measure but also
                    improve your mathematical skills.
                  </p>
                  <div className="btn_particepent">Read Article</div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className={styleCss.img_wrap}>
                  <img src="./images/blog_img.png" alt="blog_img" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5">
          <div className="container_elm_2">
            <div className="row">
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
              <div className="col-md-4 mb-5 mt-5">
                <BlogCard />
              </div>
            </div>
          </div>
        </section>
        <section className="recent_heihglightForm">
          <RegisterForm img="images/registerform_img_1.png" />
        </section>
      </main>
    </>
  );
};

export default Blog;
