import React, { useState, useEffect } from "react";
import ModelPopup from "./ModelPopup";
import styleCss from "./NextCity.module.css";

const USER_API_URL = "https://stag-api.msvgo.com";
const NextCity = () => {
  const [show, setShow] = useState(false);
  const [mycity, setMycity] = useState(false);
  const handleShow = () => setShow(true);
  const handelClose = (child) => setShow(child);
  const handleShowMycity = () => setMycity(true);
  const handelCloseMycity = (child) => setMycity(child);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [cityName, setCityName] = useState("");
  const [selectedCityName, setSelectedCityName] = useState("");
  const [addIsValid, setAddIsValid] = useState(false);
  const [likeIsValid, setLikeIsValid] = useState(false);
  const [cities, setCities] = useState([]);
  const [userLikes, setUserLikes] = useState([]);

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (cityName && mobileNumber) {
      setAddIsValid(true);
    } else {
      setAddIsValid(false);
    }
  }, [mobileNumber, cityName]);

  useEffect(() => {
    if (selectedCityName && mobileNumber) {
      setLikeIsValid(true);
    } else {
      setLikeIsValid(false);
    }
  }, [mobileNumber, selectedCityName]);

  useEffect(() => {
    setErrorMessage("");
    setSuccessMessage("");
    setCityName("");
    setMobileNumber("");
  }, [show, mycity]);

  const handleAddCity = async () => {
    if (loading) return;
    setErrorMessage("");
    setSuccessMessage("");
    if (!cityName) {
      setErrorMessage("Please enter your city name");
      return;
    }
    if (!mobileNumber.match(/^\d{10}$/)) {
      setErrorMessage("Please enter valid mobile number");
      return;
    }
    setLoading(true);
    let body = {
      mobile: mobileNumber,
      city: cityName,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await (
        await fetch(`${USER_API_URL}/v1/genius_hunt/add_city`, requestOptions)
      ).json();
      if (response.code === 200) {
        setSuccessMessage("City added successfully");
      } else {
        setErrorMessage("City already added");
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage("Registeration failed, Please try again later!");
    }
    setLoading(false);
  };

  const handleCityLike = async () => {
    if (loading) return;
    setErrorMessage("");
    setSuccessMessage("");
    if (!mobileNumber.match(/^\d{10}$/)) {
      setErrorMessage("Please enter valid mobile number");
      return;
    }
    setLoading(true);
    let body = {
      mobile: mobileNumber,
      city: selectedCityName,
      is_like: "1",
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };

    try {
      const response = await (
        await fetch(`${USER_API_URL}/v1/genius_hunt/add_like`, requestOptions)
      ).json();
      if (response.code === 200) {
        setSuccessMessage("Like Added");
        setUserLikes(q => [...q, selectedCityName]);
        getCities();
        handelCloseMycity();
      } else {
        setErrorMessage("Like already Added");
      }
    } catch (err) {
      setLoading(false);
      setErrorMessage("Failed, please try Again");
    }
    setLoading(false);
  };

  const getCities = async () => {
    try {
      const response = await (
        await fetch(`${USER_API_URL}/v1/genius_hunt/get_cities`)
      ).json();
      if (response.code === 200) {
        setCities(response?.data);
      }
    } catch (err) { }
  };

  return (
    <>
      <section className={styleCss.NextCity}>
        <div className="container_elm_2">
          {userLikes ?
            <>
              <h3>Thanks for voting your city!</h3>
              <p>We have added it to our route!</p>
            </> : <>
              <h3>Want us to visit you next?</h3>
              <p>Vote for your city, and we will add it to our route!</p>
            </>}

          <div className={styleCss.NextCityList}>
            <div className="row">
              {cities &&
                cities.length > 0 &&
                cities.map((city, index) => {
                  return (
                    <div className="col-lg-3 col-md-4 col-6 mb-5" key={index}>
                      <div
                        className={styleCss.cityelm}
                        onClick={() => {
                          setSelectedCityName(city?.City_name);
                          handleShowMycity();
                        }}
                      >
                        <div className={styleCss.cityelmImg}>
                          <img
                            src={`./images/${city?.City_name.toLowerCase()}.png`}
                            alt={city?.City_name}
                          />
                          <h6>{city?.City_name}</h6>
                        </div>
                        <div className={`${styleCss.btnbadge} ${userLikes.indexOf(city?.City_name) > -1 ? styleCss.active : ""}`}>
                          <div className={styleCss.icon}>
                            {
                              userLikes.indexOf(city?.City_name) > -1 ?
                                <img src="./images/hand_icon_active.svg" alt="hand_icon" /> :
                                <img src="./images/hand_icon.svg" alt="hand_icon" />
                            }
                          </div>
                          <span>{city?.likes}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="col-lg-3 col-md-4 col-6 mb-5">
                <div className={styleCss.cityelm}>
                  <div
                    className={`${styleCss.cityelmImg} ${styleCss.addcityelm}`}
                    onClick={handleShow}
                  >
                    <img src="./images/addbtn_img.png" alt="delhi" />
                    <h6>Add City</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* next city popup */}
      <ModelPopup show={show} handelFromParent={handelClose}>
        <div className="model_content pb-5">
          <div>
            <h3>Did not find your city in the list ?</h3>
            <h5>No worries you can type below</h5>
            <div className="form-group">
              <input
                type="text"
                className="form-control mb-5"
                placeholder="Enter your city name *"
                value={cityName}
                maxLength={50}
                onChange={(e) =>
                  setCityName(e.target.value.replace(/[^a-z ]/gi, "").replace(/^ +/, '').replace(/ {2}/g, " "))
                }
              />
            </div>

            <p>
              Give us a contact method and we’ll reach out to organize a genius
              hunt in your city
            </p>
            <div className="form-group">
              <div className="input-group justify-content-center mb-3">
                <span className="input-group-text" id="basic-addon1">
                  +91
                </span>
                <input
                  type="text"
                  className="form-control m-0"
                  placeholder="Enter phone number *"
                  value={mobileNumber}
                  onInput={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9]+/g, "");
                    inputValue = inputValue.replace(/^0/, "");
                    inputValue = inputValue.substring(0, 10);
                    setMobileNumber(inputValue);
                    setErrorMessage("");
                  }}
                  maxLength={10}
                />
              </div>
            </div>
            <div className="mb-2 mt-3" style={{ height: "24px" }}>
              {errorMessage && (
                <div
                  className={"text text-danger text-center"}
                >
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div
                  className={"text text-success text-center"}
                >
                  {successMessage}
                </div>
              )}
            </div>
            <div className="btn_wrap text-center mt-4">
              <button
                type="submit"
                className="btn_particepent"
                disabled={!addIsValid}
                onClick={handleAddCity}
              >
                Invite Now
              </button>
            </div>
          </div>
        </div>
      </ModelPopup>

      {/* my city popup model */}
      <ModelPopup show={mycity} handelFromParent={handelCloseMycity}>
        <div className="model_content pb-5">
          <div>
            <h3>Vote for {selectedCityName}!</h3>
            <div className="text-center mb-4">
              <img
                src={`./images/${selectedCityName.toLowerCase()}.png`}
                alt={selectedCityName}
              />
            </div>

            <p>
              Give us a contact method and we’ll reach out to organize a genius
              hunt in your city
            </p>
            <div className="form-group">
              <div className="input-group justify-content-center mb-3">
                <span className="input-group-text" id="basic-addon1">
                  +91
                </span>
                <input
                  type="text"
                  className="form-control m-0"
                  placeholder="Enter phone number *"
                  value={mobileNumber}
                  maxLength={10}
                  onInput={(e) => {
                    let inputValue = e.target.value;
                    inputValue = inputValue.replace(/[^0-9]+/g, "");
                    inputValue = inputValue.replace(/^0/, "");
                    inputValue = inputValue.substring(0, 10);
                    setMobileNumber(inputValue);
                    setErrorMessage("");
                  }}
                />
              </div>
            </div>
            {errorMessage && (
              <div
                className={
                  "text text-danger text-center " +
                  (errorMessage ? "mb-2 mt-3" : "")
                }
              >
                {errorMessage}
              </div>
            )}
            {successMessage && (
              <div
                className={
                  "text text-success text-center " +
                  (successMessage ? "mb-2 mt-3" : "")
                }
              >
                {successMessage}
              </div>
            )}
            <div className="btn_wrap text-center mt-4">
              <button
                type="submit"
                className="btn_particepent"
                disabled={!likeIsValid}
                onClick={handleCityLike}
              >
                Vote Now
              </button>
            </div>
          </div>
        </div>
      </ModelPopup>
    </>
  );
};

export default NextCity;
