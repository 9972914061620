import React from "react";
import styleCss from "./Blog.module.css";

const BlogCard = () => {
  return (
    <>
      <div className={styleCss.BlogCard}>
        <div className={styleCss.img_wraper}>
          <img src="./images/blogCard_img.png" alt="blogCard_img" />
        </div>
        <div className={styleCss.content}>
          <h6>July 20. 2022</h6>
          <h5>Quadratic Equations with Two Imaginary Solutions</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
            vulputate libero et velit interdum, ac aliquet odio mattis. Class
            aptent taciti sociosqu ad litora torquent per conubia nostra, per
            inceptos himenaeos.
          </p>
          <a href="/">Read in 8 minutes</a>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
