import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import stylecss from "./ModelPopup.module.css";

const ModelPopup = (props) => {
  const handleClose = () => {
    props.handelFromParent(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        onHide={handleClose}
        animation={true}
        centered
        backdropClassName={props.backdropClassName}
        className={`${stylecss.ModelPopup} ${
          props.className && props.className
        }`}
      >
        <Modal.Header>
          <div className={stylecss.btnClose} onClick={handleClose}>
            <img src="./images/Cancel.png" alt="Cancel" />
          </div>
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModelPopup;
