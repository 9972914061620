import React from "react";
import styleCss from "./MathiqGame.module.css";

const MathiqGame = () => {
  return (
    <>
      <section className={styleCss.MathiqGame}>
        <div className="container_elm_2">
          <div className="row align-items-center">
            <div className="col-lg-8 col-12">
              <div className={styleCss.content}>
                <h3>Practice & Participate</h3>
                <p>
                  Give it a shot right here, and find out why everyone is <br />
                  loving this quick 90-second game.
                </p>
                <div className={styleCss.contentFooter}>
                  <h5>
                    Add it to your daily 20 minute study <br /> habit on the
                    <a href="https://msvgo.com/learn">
                      <img src="./images/msvgoLogo.png" alt="msvgoLogo.png" />
                    </a>
                    app now!
                  </h5>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.hurix.msvgolive&hl=en_IN&gl=US"
                    className={styleCss.applogo}
                  >
                    <img
                      src="./images/googlestore_img.png"
                      alt="googlestore_img"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className={styleCss.iframe_wrap}>
                <iframe
                  src="https://liga.msvgo.com/"
                  frameBorder="0"
                  title="mathIq"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MathiqGame;
