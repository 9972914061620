/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import geniusimg from "../../assets/images/msvgo_genius.svg";
import msvgo_log from "../../assets/images/msvgo_log.png";

const USER_API_URL = "https://api.msvgo.com";

function Leaderboard(props) {
    const { slug } = useParams();
    const navigate = useNavigate();
    const [minHeight, setMinHeight] = useState(0);
    const [leaderboard, setLeaderboard] = useState([]);
    const [society, setSociety] = useState({});

    let resizeWindow = () => {
        setMinHeight(window.innerHeight);
    };

    useEffect(() => {
        getSociety();
        getLeaderboard();
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    const getSociety = () => {
        axios
            .post(`${USER_API_URL}/v1/genius_hunt/get_society`, { name: slug })
            .then((res) => {
                if (res.data.code === 200) {
                    setSociety(res.data.data);
                } else {
                    navigate("/");
                }
            });
    };

    const getLeaderboard = () => {
        axios
            .get(`${USER_API_URL}/v1/genius_hunt/leaderboard?name=${slug}`)
            .then((res) => {
                if (res.data.data) {
                    setLeaderboard(res.data.data.slice(0, 10));
                }
            });
    };

    return (
        <>
            <div className='leaderboard_container leaderboard_bg'>
                <div className="leaderboard">
                    <div className="leaderboard_head">
                        <div className="header_img text-center">
                            <img src={geniusimg} alt="geniusimg" />
                            <h3 className="text_yellow">{society?.name}</h3>
                            <h3 className="text_light">LEADERBOARD</h3>
                        </div>

                    </div>
                    <div className="leaderboard_list_wrap">
                        <div className="divtable">
                            <div className='head'>
                                <div className='row'>
                                    <div className='col-2'>RANK</div>
                                    <div className='col-6'>NAME</div>
                                    <div className='col-2'>GRADE</div>
                                    <div className='col-2'>SCORE</div>
                                </div>
                            </div>
                            <div className='tbody'>
                                {leaderboard && leaderboard.map((leader, i) => {
                                    return (
                                        <div className='row' key={'l_' + i}>
                                            <div className='col-2'>{i + 1}</div>
                                            <div className='col-6'>{leader?.user_name}</div>
                                            <div className='col-2'>{leader?.grade}</div>
                                            <div className='col-2'>{(99 + (14 * (leader?.max_score - 48)) / 34).toFixed(0)}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="text-center bottom_content">
                            <h5>REGISTER NOW at msvgo.com/genius-hunt/{slug}</h5>
                            <img src={msvgo_log} alt="msvgo_log" className='mdvgoLogo' />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Leaderboard;
