/* eslint-disable jsx-a11y/alt-text */
import { useState, useEffect } from "react";
import GameScreenDaily from "./GameScreenDaily";

function Daily49() {
  const [score, setScore] = useState(0);
  const [screen, setScreen] = useState("gameScreen");

  const updateScreen = (no) => {
    setScreen(no);
  };

  let component;
  switch (screen) {
    case "gameScreen":
      component = (
        <GameScreenDaily updateScreen={updateScreen} setScore={setScore} />
      );
      break;
    default:
      component = <GameScreenDaily updateScreen={updateScreen} />;
      break;
  }

  return (
    <div className="homepage">
      <main>
        <div className="row m-0 mainBg">
          <div className="col-12 p-0">
            <div className="row main-container">{component}</div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Daily49;
