import React from "react";
import styleCss from "./MathiqFeature.module.css";

function MathiqFeature() {
  return (
    <>
      <section className={styleCss.MathiqFeature}>
        <div className="container_elm_2">
          <div className={`row ${styleCss.row}`}>
            <div className="col-lg-4">
              <div className={styleCss.img_wrap}>
                <video
                  src="./videos/math iq game play.mp4"
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className={styleCss.content}>
                <h3>What is MathIQ?</h3>
                <p>
                  Ability to solve problems on the go. Ranging from simple to
                  complex equations, <br /> we can help not only measure but
                  also improve your mathematical skills.
                </p>
                <ul>
                  <li>
                    Develop quantitative <br /> thinking
                  </li>
                  <li>
                    Improves focus on <br /> numericals
                  </li>
                  <li>
                    Fastens problem-solving <br /> ability
                  </li>
                  <li>
                    Indicator of a<br /> student’s potential
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MathiqFeature;
