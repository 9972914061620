/* eslint-disable jsx-a11y/alt-text */
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Daily49 from "./components/Daily49";
import GeniusHunt from "./components/GeniusHunt/GeniusHunt";
import GeniusHuntLeaderboard from "./components/GeniusHunt/LeaderBoard";
import GeniusHuntLeaderboard2 from "./components/GeniusHunt/LeaderBoard2";
import GeniusHuntNew from "./components/GeniusHunt";
import ViewmoreRecentHeighlights from "./components/ViewmoreRecentHeighlights";
import Blog from "./components/Blog";
import BlogArticle from "./components/BlogArticle";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/daily49" index element={<Daily49 />} />
        <Route path="/genius-hunt" index element={<GeniusHuntNew />} />
        <Route path="/genius-hunt/:slug" index element={<GeniusHunt />} />
        <Route
          path="/genius-leaderboard/:slug"
          index
          element={<GeniusHuntLeaderboard />}
        />
        <Route
          path="/genius-leaderboard2/:slug"
          index
          element={<GeniusHuntLeaderboard2 />}
        />
        <Route
          path="/recent-highlights"
          index
          element={<ViewmoreRecentHeighlights />}
        />
        <Route path="/Blog" index element={<Blog />} />
        <Route path="/BlogArticle" index element={<BlogArticle />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

// for offline
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";
// import React from "react";
// import { HashRouter, Routes, Route } from "react-router-dom";
// import Home from "./components/Home";
// import Daily49 from "./components/Daily49";
// import GeniusHunt from "./components/GeniusHunt/GeniusHunt";
// import GeniusHuntLeaderboard from "./components/GeniusHunt/LeaderBoard";
// import GeniusHuntLeaderboard2 from "./components/GeniusHunt/LeaderBoard2";

// function App() {
//   return (
//     <HashRouter>
//       <Routes>
//         <Route path="/" index element={<GeniusHuntLeaderboard2 />} />
//         <Route path="/daily49" index element={<Daily49 />} />
//         <Route path="/genius-hunt/:slug" index element={<GeniusHunt />} />
//         <Route path="/genius-leaderboard/:slug" index element={<GeniusHuntLeaderboard />} />
//         <Route path="/genius-leaderboard2/:slug" index element={<GeniusHuntLeaderboard2 />} />
//       </Routes>
//     </HashRouter>
//   );
// }

// export default App;
