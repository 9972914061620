/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import backbtn from "../../assets/images/backbtn.png";
import logo_unit from "../../assets/images/logo_unit.svg";
import sharebtn from "../../assets/images/sharebtn.svg";
import replaybtn from "../../assets/images/replaybtn.svg";
import share_icons_items from "../../assets/images/share_icons_items.svg";
import loadingIcons from "../../assets/images/loadingIcons.svg";
import Group_2 from "../../assets/images/Group_2.png";
import boy_snoozing from "../../assets/images/Sleepy_Head.svg";
import capable_do_beter from "../../assets/images/Capable_of_Doing_Better.svg";
import Better_than_most from "../../assets/images/Better_than_most.svg";
import Master_of_numbers from "../../assets/images/MasterOf_Numbers.svg";
import Genius from "../../assets/images/Budding_Genius.svg";
import einstein from "../../assets/images/einstein.svg";
import Close_icon from "../../assets/images/Close_icon.png";

const ResultScreen = (props) => {
  const [showShare, setShowShare] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const formatedScore = (99 + (14 * (props.score - 48)) / 34).toFixed(0);
  // const formatedScore = 70
  const [userImage, setUserImage] = useState("");
  const [hounerdWith, setHounerWith] = useState("");
  const [show, setShow] = useState(false);
  const [shareImageUrl, setShareImageUrl] = useState("");
  const [appUrl, setAppUrl] = useState(
    "https://play.google.com/store/apps/details?id=com.hurix.msvgolive&hl=en_IN&gl=US"
  );
  const currentUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (navigator.share) {
      setShowShare(true);
    }
    var ua = navigator.userAgent.toLowerCase();
    if (/ipad|iphone|ipod/.test(ua)) {
      setAppUrl(
        "https://apps.apple.com/in/app/msvgo-learning-app-for-6-12th/id1502069282"
      );
    }
    getShareImageUrl();
    // formatedScore = 166;
    // if (formatedScore < 82) {
    if (formatedScore < 82) {
      setUserImage(boy_snoozing);
      setHounerWith("A Sleepy Head!");
    } else if (formatedScore >= 82 && formatedScore <= 92) {
      setUserImage(capable_do_beter);
      setHounerWith("Capable of Doing Better!");
    } else if (formatedScore >= 93 && formatedScore <= 108) {
      setUserImage(Better_than_most);
      setHounerWith("Better Than Most!");
    } else if (formatedScore >= 109 && formatedScore <= 129) {
      setUserImage(Master_of_numbers);
      setHounerWith("A Master of Numbers!");
    } else if (formatedScore >= 130 && formatedScore < 159) {
      setUserImage(Genius);
      setHounerWith("A Budding Genius!");
    }
    if (formatedScore >= 160) {
      setUserImage(einstein);
      setHounerWith("The Next Einstein!");
    }
    if (window.self !== window.top) {
      setIsIframe(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getShareImageUrl = () => {
    axios
      .get(
        `https://stag-liga.msvgo.com/share-liga-image?score=${formatedScore}`
      )
      .then(function (response) {
        if (response.data.code === 200) {
          setShareImageUrl(response.data.url);
          axios
            .get(response.data.url, { responseType: "blob" })
            .then(function (response) { });
        }
      });
  };

  const handleShareClick = () => {
    if (shareLoading) return;
    setShareLoading(true);
    if (navigator.share) {
      let shareTitle = "INTERSCHOOL CHALENGE";
      let shareText = `I am *${hounerdWith}* Discover yourself @:`;
      axios
        .get(shareImageUrl, { responseType: "blob" })
        .then(function (response) {
          var file = new File([response.data], "msvgo-liga.png", {
            type: "image/png",
          });
          var filesArray = [file];

          if (navigator.canShare && navigator.canShare({ files: filesArray })) {
            navigator
              .share({
                files: filesArray,
                title: shareTitle,
                text: shareText,
                url: currentUrl,
              })
              .catch((error) => console.log("Error sharing", error));
          } else {
            navigator
              .share({
                title: shareTitle,
                text: shareText,
                url: currentUrl,
              })
              .catch((error) => console.log("Error sharing", error));
          }
          setShareLoading(false);
        })
        .catch((error) => setShareLoading(false));
    } else {
      handleShow();
      setShareLoading(false);
    }
  };

  return (
    <>
      {/* <!-- container 2 --> */}
      <div className="col-12 col-lg-4 p-0 px-lg-3 resultScreen_wrap">
        <div id="resultScreen" className="align-items-center">
          <div className="topbrand">
            <div className="text-center d-flex justify-content-center logo_wrap ">
              {" "}
              <img
                src={backbtn}
                className="back_btn"
                alt="Back"
                onClick={() => props.updateScreen("gameScreen")}
              />
              <div className="content_wraper">
                <div className="title_math score_heading">
                  {props?.user?.name.split(" ").shift()}{"'s "}
                  <img src={logo_unit} className="score_logo" alt="topbg" />
                </div>
              </div>
            </div>
          </div>
          <div className="centerBand text-center">
            <div className="centerBand_container">
              <div className="centerBand_container_wrapper">
                <div className="center_score_content">
                  <div className="d-flex centerBand_btn_wrap justify-content-center align-items-center">
                    <div className="scrore_count">
                      <div id="t5">{formatedScore}</div>
                    </div>
                  </div>
                </div>

                <div className="score_disc">
                  <div
                    className={
                      "score_disc_item " + (formatedScore > 160 ? "active" : "")
                    }
                  >
                    <div className="item_1">160+</div>
                    <div className="item_2">The Next Einstein!</div>
                  </div>
                  <div
                    className={
                      "score_disc_item " +
                      (formatedScore >= 130 && formatedScore <= 159
                        ? "active"
                        : "")
                    }
                  >
                    <div className="item_1">130 - 159</div>
                    <div className="item_2">A Budding Genius!</div>
                  </div>
                  <div
                    className={
                      "score_disc_item " +
                      (formatedScore >= 109 && formatedScore <= 129
                        ? "active"
                        : "")
                    }
                  >
                    <div className="item_1">109 - 129</div>
                    <div className="item_2">A Master of Numbers!</div>
                  </div>
                  <div
                    className={
                      "score_disc_item " +
                      (formatedScore >= 93 && formatedScore <= 108
                        ? "active"
                        : "")
                    }
                  >
                    <div className="item_1">93 - 108</div>
                    <div className="item_2">Better Than Most!</div>
                  </div>
                  <div
                    className={
                      "score_disc_item " +
                      (formatedScore >= 82 && formatedScore <= 92
                        ? "active"
                        : "")
                    }
                  >
                    <div className="item_1">82 - 92</div>
                    <div className="item_2">Capable of Doing Better!</div>
                  </div>
                  <div
                    className={
                      "score_disc_item " + (formatedScore < 82 ? "active" : "")
                    }
                  >
                    <div className="item_1">{"< 82"}</div>
                    <div className="item_2">A Sleepy Head!</div>
                  </div>
                </div>
                <div className="text-right avtar_wraper position-relative">
                  <img src={userImage} className="avtar_lead_img" alt="user" />
                  <img src={Group_2} className="wave_img_result" alt="Wave" />
                </div>
              </div>
            </div>
          </div>
          <div className="container text-center">
            <img
              src={sharebtn}
              id="shareIcon2"
              className="share_social_media_icon mb-3"
              alt="share_loader"
              onClick={handleShareClick}
            />

            <img
              src={replaybtn}
              id="reloadIcon"
              alt="Replay"
              onClick={() => props.updateScreen("gameScreen")}
            />
            {/* <div className="bottom_card">
              <div className="bottom_card_content">
                <h5 className="toptitle">
                  All India Leaderboard Check out your rank on the msvgo app.
                </h5>
                <div className="social_media">
                  <div className="row">
                    <div className="col-6 text-right">
                      <a
                        href="https://msvgo.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <img src={msvgo_new_logo} />{" "}
                      </a>
                    </div>
                    <div className="col-6 text-left">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.hurix.msvgolive"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        <img src={google_play} />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Footer Bar */}
        {!isIframe && (
          <div className="foore_icons d-none">
            {/* <a
                className="icons_itesm home_icon"
                href={appUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={home_btn} alt="footer_icons_items" />
              </a>
              <a
                className="icons_itesm"
                href="https://search.msvgo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={searc_icon_items} alt="footer_icons_items" />
              </a> */}
            {showShare && (
              <div
                className="icons_itesm share_social_media share_navigate"
                data-home="home"
                onClick={handleShareClick}
              >
                {shareLoading ? (
                  <img src={loadingIcons} alt="footer_icons_items" />
                ) : (
                  <img src={share_icons_items} alt="footer_icons_items" />
                )}
              </div>
            )}
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          // backdrop="static"
          keyboard={false}
          className="custum_share_modal"
          centered
        >
          <Modal.Body>
            <div className="row text-center" id="shareIcon">
              <button
                type="button"
                className="close closebtn2"
                onClick={() => {
                  handleClose();
                }}
              >
                <img src={Close_icon} alt="Modal Close" />
              </button>
              <div className="col-12 p-0">
                <div className="scoreMainPopup">
                  <div className="textBand"></div>{" "}
                  <span className="line"></span>
                  <div className="text-center model_score_wraper">
                    <div className="modal_score_title">
                      Your <img src={logo_unit} alt="share_m_iq_icon" />{" "}
                    </div>
                    <div className="text-center model_score pt-3">
                      {formatedScore}
                    </div>
                    <h5 className="score_dis_text pb-3">{hounerdWith}</h5>
                    <div className="startMain d-flex justify-content-center">
                      {" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 toptext">Share it on</div>

              <div className="col-12 mt-2">
                <div className="iocnsAll d-flex justify-content-center">
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=" +
                      encodeURI(currentUrl)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon_all"
                    id="facebook"
                  ></a>
                  <a
                    href={"https://www.instagram.com/?url=" + currentUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon_all"
                    id="instagram"
                  ></a>
                  <a
                    href={`https://wa.me/?text=I am *${hounerdWith}* Discover yourself @: ${currentUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon_all"
                    id="whatsapp"
                  ></a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ResultScreen;
