/* eslint-disable jsx-a11y/alt-text */
import msvgo_white_logo from "../../assets/images/msvgo_white_logo.svg";
import google_play_black from "../../assets/images/google_play_black.png";
import app_store_black from "../../assets/images/app_store_black.png";

const StartScreen = (props) => {
  return (
    <div className="row liga-container1">
      <div className="bg-full-glass"></div>
      <div className="col-12 p-0">
        <div className="row m-0">
          <div className="offset-1 col-10 p-0">
            <p className="text-white text-center">
              Download the msvgo app to get on the All India Leaderboard!
            </p>
          </div>
        </div>
        <div className="row m-0 my-4">
          <div className="offset-4 col-4 p-0">
            <img src={msvgo_white_logo} className="w-100" />
          </div>
        </div>
        <div className="row m-0">
          <div className="offset-2 col-8 p-0 mb-2">
            <a
              href={
                "https://play.google.com/store/apps/details?id=com.hurix.msvgolive&hl=en_IN&gl=US"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={google_play_black} width="100%" />
            </a>
          </div>
        </div>
        <div className="row m-0">
          <div className="offset-2 col-8 p-0">
            <a
              href={
                "https://apps.apple.com/in/app/msvgo-learning-app-for-6-12th/id1502069282"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={app_store_black} width="100%" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartScreen;
